import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const HomeIntro = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "pp.JPG" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  `)
  // console.log(data)
  return (
    <div className="my-24">
      <h1 className="text-4xl w-full mt-12 mb-6 text-center tracking-wider uppercase">
        Los Angeles Wedding Filmmaker
      </h1>
      <img
        src={data.file.childImageSharp.fluid.src}
        alt="This is a little about me and who I look like."
        title="Los Angeles Wedding Filmmaker"
        loading="lazy"
        className="mx-auto rounded-full w-52 h-52 object-cover my-7 lg:my-12"
      />
      <p className="px-5 text-xl sm:w-3/4 text-center sm:mx-auto leading-relaxed font-light">
        We make timeless wedding films that capture the essence of your special
        day through emotive storytelling. Using moving images and sound, we
        preserve fleeting moments, allowing you to relive every emotion. We love
        crafting unique stories and would be honored to work with you. Reach out
        to us using the details below.
      </p>
    </div>
  )
}

export default HomeIntro
